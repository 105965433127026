$footer-breakpoint: 900px;

.footer {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $footer-breakpoint) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.footer__column {
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (min-width: $footer-breakpoint) {
    margin-bottom: 0;
  }
}

.footer__list {
  margin-bottom: 0.25rem;
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
}

.footer__link {
  display: flex;
  align-items: center;
}

.footer__text {
  margin: 0;
}

.footer__icon {
  height: 1rem;
  width: 1rem;
  fill: $gray;
  padding-right: 0.625rem;
}

.footer a {
  color: $orange;
  &:visited { color: $orange;}
  &:hover,
  &:hover span.username {
    text-decoration: none;
    color: $white-off;
  }

  &:hover + .footer__icon {
      fill: $gray-light;
    
  }
}

.site-footer {
  border-top-width: 2px;
}
